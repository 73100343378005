import { Title } from "../components/Texts/Texts";
import LayoutDefault from "../layouts/LayoutDefault";

export const NotFound = () => {
  return (
    <LayoutDefault>
      <Title>NotFound</Title>
    </LayoutDefault>
  );
};
